import React,{useState} from 'react'
import { NavLink } from 'react-router-dom'

function AddressList() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const validate = () => {
        const errors = {};
        if (!firstName) errors.firstName = 'First name is required';
        if (!lastName) errors.lastName = 'Last name is required';
        if (!company) errors.company = 'Company is required';
        if (!address) errors.address = 'Address is required';
        if (!city) errors.city = 'City is required';
        if (!country) errors.country = 'Country is required';
        if (!zipcode) errors.zipcode = 'Zipcode is required';
        if (!phone) errors.phone = 'Phone number is required';
    
        setErrors(errors);
        return Object.keys(errors).length === 0;
      };
    
      const handleInputChange = (e) => {
        const { id, value } = e.target;
        switch (id) {
          case 'firstName':
            setFirstName(value);
            break;
          case 'lastName':
            setLastName(value);
            break;
          case 'company':
            setCompany(value);
            break;
          case 'address':
            setAddress(value);
            break;
          case 'address2':
            setAddress2(value);
            break;
          case 'city':
            setCity(value);
            break;
          case 'country':
            setCountry(value);
            break;
          case 'zipcode':
            setZipcode(value);
            break;
          case 'phone':
            setPhone(value);
            break;
          default:
            break;
        }
        if (value) {
          setErrors((prevErrors) => ({ ...prevErrors, [id]: '' }));
        }
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
          // Store form values (e.g., save to a database or state management)
          console.log('Form submitted', { firstName, lastName, company, address, address2, city, country, zipcode, phone });
          
         
        }
      };
    
      const handleCancel = () => {
        // Clear all form fields
        setFirstName('');
        setLastName('');
        setCompany('');
        setAddress('');
        setAddress2('');
        setCity('');
        setCountry('');
        setZipcode('');
        setPhone('');
        setErrors({});
      };
      const handleToggle = () => {
        setIsVisible(!isVisible);
      };
  return (
    <div className='container text-center'>
        <h2 className='text-center mb-5'>Addresses</h2>

        <div className='mb-5'> <NavLink to="account"> <u>Return to Account details</u></NavLink></div>

        <div>
            <button className='formbtn' onClick={handleToggle}>Add a new address </button>
        </div>
        {isVisible && (

        <div> 
            <h3 className='mt-5 mb-5'>Add a new address</h3>



            <form className='formcontrols myform' onSubmit={handleSubmit}>
        <div  className='formrow'>
           
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={handleInputChange}
            placeholder='First Name'
             className='form-controls'
          />
          {errors.firstName && <p style={{ color: 'red',textAlign:'left' }}>{errors.firstName}</p>}
        </div>
        <div  className='formrow'>
          
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={handleInputChange}
            placeholder='Last Name'
             className='form-controls'
          />
          {errors.lastName && <p style={{ color: 'red',textAlign:'left' }}>{errors.lastName}</p>}
        </div>
        <div  className='formrow'>
          
          <input
            type="text"
            id="company"
            value={company}
            onChange={handleInputChange}
            placeholder='Company'
             className='form-controls'
          />
          {errors.company && <p style={{ color: 'red',textAlign:'left'}}>{errors.company}</p>}
        </div>
        <div  className='formrow'>
          
          <input
            type="text"
            id="address"
            value={address}
            onChange={handleInputChange}
            placeholder='Address'
             className='form-controls'
          />
          {errors.address && <p style={{ color: 'red',textAlign:'left' }}>{errors.address}</p>}
        </div>
        <div  className='formrow'>
          
          <input
            type="text"
            id="address2"
            value={address2}
            onChange={handleInputChange}
            placeholder='Address2'
             className='form-controls'
          />
        </div>
        <div  className='formrow'>
         
          <input
            type="text"
            id="city"
            value={city}
            onChange={handleInputChange}
            placeholder='City'
             className='form-controls'
          />
          {errors.city && <p style={{ color: 'red' ,textAlign:'left'}}>{errors.city}</p>}
        </div>
        <div  className='formrow'>
           
          <input
            type="text"
            id="country"
            value={country}
            onChange={handleInputChange}
            placeholder='Country'
             className='form-controls'
          />
          {errors.country && <p style={{ color: 'red',textAlign:'left' }}>{errors.country}</p>}
        </div>
        <div  className='formrow'>
           
          <input
            type="text"
            id="zipcode"
            value={zipcode}
            onChange={handleInputChange}
            placeholder='zipcode'
             className='form-controls'
          />
          {errors.zipcode && <p style={{ color: 'red',textAlign:'left' }}>{errors.zipcode}</p>}
        </div>
        <div  className='formrow'>
           
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={handleInputChange}
            placeholder='Phone'
             className='form-controls'
          />
          {errors.phone && <p style={{ color: 'red',textAlign:'left' }}>{errors.phone}</p>}
        </div>
        <div className='flex gap-2 justify-center'> 
        <button className='formbtn' type="submit">Submit</button>
        <button className='formbtncancel' type="button" onClick={handleCancel}>Cancel</button>
        </div>
      </form>

        </div>
        )

           }
        <div className='mt-5 mb-10'>
            <h3> Default</h3>

            <div className='mt-5'>  
        <p>aa dfdf <br/>
        United States</p>
        </div>
        </div>
        

    </div>
  )
}

export default AddressList