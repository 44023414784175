import React from 'react' 
import { NavLink ,useLocation} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import styled from "styled-components";
function Headericons() {
  const isLoggedIn = !!localStorage.getItem('authToken'); // Check if token exists

    const location = useLocation();
  return (
    <Wrapper> 

<div className="flex items-center space-x-4 ml-6  headericons">
                <span className="cursor-pointer  relative">
                <NavLink to="/cart" >
                <FontAwesomeIcon icon={faShoppingCart} size="lg" />
              {  <span className="cart-total-item "> 0 </span>  }
                </NavLink>
                  </span>
                    <span className="cursor-pointer ">
                        <FontAwesomeIcon icon={faSearch} size="lg" />
                    </span>
                    <NavLink to={isLoggedIn ? "/account" : "/login"}   >
                    <span className="cursor-pointer ">
                        <FontAwesomeIcon icon={faUser} size="lg" />
                    </span>
                    </NavLink>
                    {
                       
                        location.pathname != "/"  &&
                        <span className="cursor-pointer">
                    <img alt="flag" src="../images/usflag.png" />  
                    </span>
                    }
                    
                    
                </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`

.cart-total-item {
 position:absolute;
 top:-10px; 
 right:-5px;
 width:15px;
 height:15px;
 background:var(--secbg);
 color:#000;
 font-size:11px;
 display:flex;
 justify-content:center;
 align-items:center;
 border-radius:50%;
}
`;
export default Headericons