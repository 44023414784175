import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axiosInstance from '../interceptors/axiosConfig';

function Account() {
  const [profileData, setProfileData] = useState(null); // State to store profile data
  const [error, setError] = useState(null); // State to store error messages

  // Fetch profile data from the API
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        
        const response = await axiosInstance.post('/store/profile',{}); // Example endpoint


        setProfileData(response.data); // Update state with the fetched data
      } catch (err) {
        setError('Failed to fetch profile data'); // Handle error
      }
    };

    fetchProfileData(); // Trigger the API call when the component mounts
  }, []);

  if (error) {
    return <div className='container'><p>{error}</p></div>;
  }

  if (!profileData) {
    return <div className='container'><p>Loading profile...</p></div>; // Show loading state
  }

  return (
    <div className='container'>
      <div className='grid grid-two-column'>
        <div>
          <h2 className='mb-2'>Your Account</h2>
          <div>
            <NavLink to="/login">
              <u>Log out</u>
            </NavLink>
          </div>

          <h2 className='mt-10'>Order history</h2>
          <p>
            {/* {profileData.orders.length > 0 ? (
            <ul>
              {profileData.orders.map((order) => (
                <li key={order.id}>Order #{order.id}: {order.status}</li>
              ))}
            </ul>
          ) : (
            "You haven't placed any orders yet."
          )} */}
          </p>
        </div>

        <div>
          <h2>Account details</h2>
          <div>
            <p>
              {profileData.first_name} <br />
            
            </p>
          </div>

          <div className='mt-10'>
            <NavLink to="/addresslist">
              <u>View addresses</u>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
