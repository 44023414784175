import React from 'react'
import { Button } from "../styles/ButtonNoarmal";
import styled from "styled-components";
function NewsLetterPage() {
  return (
    <Wrapper className="newsletter">
       <h2> Newsletter Subscription</h2> 
       <p  > 
Stay ahead of the curve by subscribing to our newsletter and be the first to receive <br />  
the latest in fashion and exclusive updates.</p>
<div className='flex justify-center'>
    <input type='text' /> <Button className="btngray">Subscribe</Button>  
</div>

        

    </Wrapper>
  )
}
const Wrapper = styled.section`
  
text-align:center;
input{
width:100%;
max-width:300px;
}
h2{ 
text-align: center;
font-family: "Playfair Display";
font-size: 34px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 1.7px;
}
p{ 
color:   #414042;
margin:50px 0px;
text-align: center;
font-family: "Playfair Display";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.8px;
}
Button{
display: flex;
height: 51px;
padding: 0px 30px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
}
`;
export default NewsLetterPage