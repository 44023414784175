import styled from "styled-components";
 
import ProductList from "./front-end/ProductList";
import Sort from "./front-end/Sort";
 

const Products = () => {
  return (
    <Wrapper>
      <div className="container grid grid-filter-column2">
      <div className="mb-10">
        <img src="../images/bannerimage1.jpg" />  
      </div>

        <section className="product-view--sort">
          <div className="sort-filter mb-2">
            <Sort />
          </div>
          <div className="main-product">
           
            <ProductList />
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .grid-filter-column {
    grid-template-columns: 0.2fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-filter-column {
      grid-template-columns: 1fr;
    }
  }
`;

export default Products;
