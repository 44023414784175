import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import './i18n';  // Import i18n configuration
 
 

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
    
        <App />
     
 
);
 
