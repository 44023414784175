import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../styles/Button";

const HeroSection = ({ myData  }) => {
  const { name } = myData;

  return (
    <Wrapper>
      <div className="herosection">
      <video className="background-video" autoPlay loop muted>
        <source src="./images/Herosection.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        <div className="herobgimg"> 
        <div className="hero-section-data">
          <div className="text-center flex-1"> 
         
          <h1 dangerouslySetInnerHTML={{ __html: name }} />
           <div className="flex justify-center"> 
            <NavLink>
              <Button className="rosebtn shopnowbtn">SHOP NOW</Button>
            </NavLink>
            </div>
            </div>
          </div> 
          </div> 
         
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding:  0;
 h1{ 
 color:#fff;
 }
  video {
      position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container without stretching */
  object-position: center; /* Centers the video within the container */
 
  }
    .herobgimg {
    position:absolute;
    z-index:2;
    top:0px;
    width:100%;
    height:100%;
    padding-bottom:100px;
    }
   
.herosection{
    background: no-repeat url("../images/bannerimage.webp");
    background-size:cover;
    height:800px; 
    position:relative;}

   .herosection:before{
     content:"";
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    background-color:rgba(0,0,0,0.5);
    width:100%;
    height:100%;
    z-index:2;
    }
    .hero-section-data{
    height:100%;
    display:flex;
    align-items:flex-end;
    }
  .hero-section-data {
    p {
      margin: 2rem 0;
    }
    h1 {     
     font-family: "Stay Classy Duo Serif";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform:uppercase;
    }
    .intro-data {
      margin-bottom: 0;
    }
  }

  .hero-section-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
   

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
     
  }
`;

export default HeroSection;
