import React from "react";
import { BrowserRouter as Router, Routes, Route ,Navigate } from "react-router-dom";
import Home from "./Home";
import Products from "./Products";
import Contact from "./Contact";
import Cart from "./Cart";
import SingleProduct from "./SingleProduct";
import ErrorPage from "./ErrorPage";
import { GlobalStyle } from "./styles/GlobalStyle";
import { ThemeProvider } from "styled-components";
import Header from "./front-end/Header";
import Footer from "./front-end/Footer";
import Layout from "./front-end/shared/Layout";
import Login from "./front-end/Login"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ResetPassword from "./front-end/ResetPassword";
import Register from "./front-end/Register";
import Account from "./front-end/Account";
import AddressList from "./front-end/AddressList";
import ScrollToTop from './front-end/ScrollToTop';

const App = () => {
  const token = localStorage.getItem('authToken'); // Check for token
  const theme = {
    colors: {
      heading: "rgb(24 24 29)",
      text: "rgba(29 ,29, 29, .8)",
      white: "#fff",
      black: " #414042",
      helper: "#8490ff",
      bg: "#E8E3DA",
      footer_bg: "#0a1435",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#414042",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    media: {
      lgwindow:"1200px",
      mobile: "768px",
      tab: "998px",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
      <ScrollToTop />
        <GlobalStyle />
        <Header />
        
         
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/" element={<Layout />}>
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/singleproduct/:id" element={<SingleProduct />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={token ? <Navigate to="/" /> : <Login />} />
          <Route path="/resetpassword" element={<ResetPassword />} /> 
          <Route path="/register" element={<Register />} />  
          <Route path="/account" element={<Account />} />  
          <Route path="/addresslist" element={<AddressList />} />   
          <Route path="*" element={<ErrorPage />} />
          
          </Route>
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
