import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";


const Nav = ( {setMenuIcon}) => {
 
 

  const Nav = styled.nav`
  
  .navbar {
  display:flex;
  }
    .navbar li a{
       color: #FFF;
font-family: "Stay Classy Duo Serif";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal; 
text-transform: uppercase;
        }
        
.navbar-lists { 
    column-gap:25px;  
 .navbar-link {
        &:link,
        &:visited {           
          color: ${({ theme }) => theme.colors.white};
          transition: color 0.3s linear;
        }

        &:hover,
        &:active {
        text-decoration:underline;
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }


   
 

    .cart-trolley--link {
      position: relative;

      .cart-trolley {
        position: relative;
        font-size: 3.2rem;
      }
     
      .cart-total--item {
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        background-color: #000;
        color: #000;
        border-radius: 50%;
        display: grid;
        place-items: center;
        top: -20%;
        left: 70%;
        background-color: ${({ theme }) => theme.colors.helper};
      }
    }

    .user-login--name {
      text-transform: capitalize;
    }

    .user-logout,
    .user-login {
      
      padding: 0.8rem 1.4rem;
    }

    @media (max-width: ${({ theme }) => theme.media.lgwindow}) {
     
      

      .active .navbar-lists {
       
        .navbar-link {
          
        }
      }
      .cart-trolley--link {
        position: relative;
        .cart-trolley {
          position: relative;
          font-size: 5.2rem;
        }

        .cart-total--item {
          width: 4.2rem;
          height: 4.2rem;
          font-size: 2rem;
        }
      }

      .user-logout,
      .user-login {
        font-size: 2.2rem;
        padding: 0.8rem 1.4rem;
      }
    }
  `;

  return (
    <Nav>
      {/* two button for open and close of menu */}
     
      
      <div  className="navbar">
       
      <ul className="navbar-lists flex  ">
                    <li className="cursor-pointer"><NavLink
              to="/"
              className="navbar-link ">HOME</NavLink></li>
                    <li className="cursor-pointer"> <NavLink
              to="/products"
              className="navbar-link "
             >GIRLS</NavLink></li>
                    <li className="cursor-pointer"><NavLink
              to="/products"
              className="navbar-link "
             >BOYS</NavLink></li>
                    <li className="cursor-pointer"><NavLink
              to="/products"
              className="navbar-link "
             >ACCESSORIES</NavLink></li>
                    <li className="cursor-pointer"><NavLink
              to="/products"
              className="navbar-link "
             >Collections</NavLink></li>
                    <li className="cursor-pointer"><NavLink
              to="/products"
              className="navbar-link "
             >ava prive</NavLink></li>
                    <li className="cursor-pointer"><NavLink
              to="/products"
              className="navbar-link "
             >AVA heritage</NavLink></li>
              <li className="cursor-pointer"><NavLink
              to="/products"
              className="navbar-link "
             >our story</NavLink></li>
            <li className="cursor-pointer"><NavLink
              to="/products"
              className="navbar-link "
             >sale</NavLink></li>
                </ul>

                
       

        
      </div>
    </Nav>
  );
};

export default Nav;
