import React from 'react'
import styled from "styled-components";
function ShippingPolicy() {
  return (
    <Wrapper className="container">

        <ul className='grid grid-two-column'>
            <li> <svg xmlns="http://www.w3.org/2000/svg" width="55" height="67" viewBox="0 0 55 67" fill="none">
<path d="M40.8726 17.6462H16.1082V23.1086H17.4841V43.5699H39.4966V23.1086H40.8726V17.6462ZM38.1207 42.2036H18.86V23.1086H38.1207V42.2036ZM39.4972 21.7429L39.4966 21.7198H17.4841V21.7429V19.012H39.4972V21.7429Z" fill="black"/>
<path d="M30.3604 7.8888L38.475 4.24829C38.7984 4.10303 38.9431 3.72408 38.7963 3.40195C38.6494 3.07981 38.2676 2.93723 37.9436 3.08249L28.0335 7.52861C27.8321 7.61866 27.7122 7.8009 27.676 8.00136C27.6307 8.20504 27.675 8.42534 27.8332 8.5856L35.4315 16.3078C35.6794 16.5607 36.0887 16.565 36.343 16.3179C36.5968 16.0714 36.6017 15.6667 36.3538 15.4142L30.2664 9.2288C40.1403 10.5688 47.7505 18.9652 47.7505 29.1337C47.7505 40.2348 38.6845 49.2337 27.5 49.2337C16.3166 49.2337 7.25 40.2348 7.25 29.1337C7.25 29.0967 7.25216 29.0608 7.25216 29.0238C7.24514 29.0254 7.23704 29.0265 7.22948 29.0286C7.20194 28.6733 6.92168 28.3908 6.5696 28.3908C6.19916 28.3908 5.89946 28.7006 5.89946 29.0822V29.2398H5.90216C5.96048 41.0324 15.6065 50.5737 27.5 50.5737C39.4302 50.5737 49.1005 40.975 49.1005 29.1332C49.1005 18.2561 40.9368 9.27865 30.3604 7.8888Z" fill="black"/>
</svg>  
<h3>Free U.S. Returns</h3>
<p>On all orders</p>
</li>
            <li> <svg xmlns="http://www.w3.org/2000/svg" width="77" height="71" viewBox="0 0 77 71" fill="none">
  <path d="M62.37 62.7309C64.0979 62.7362 65.764 62.0893 67.0347 60.9197C68.3055 59.75 69.0871 58.144 69.223 56.4232H74.382L74.228 39.8847L61.138 25.1155H46.277V39.4232H13.09V56.4232H17.479C17.479 58.2389 18.201 59.9802 19.4862 61.2641C20.7713 62.548 22.5144 63.2693 24.332 63.2693C26.1495 63.2693 27.8926 62.548 29.1778 61.2641C30.463 59.9802 31.185 58.2389 31.185 56.4232H55.517C55.6529 58.144 56.4344 59.75 57.7052 60.9197C58.9759 62.0893 60.6421 62.7362 62.37 62.7309ZM62.37 61.1924C60.9609 61.1924 59.6095 60.6332 58.6131 59.6378C57.6167 58.6424 57.057 57.2924 57.057 55.8847C57.057 54.477 57.6167 53.127 58.6131 52.1316C59.6095 51.1362 60.9609 50.577 62.37 50.577C63.7791 50.577 65.1304 51.1362 66.1268 52.1316C67.1232 53.127 67.683 54.477 67.683 55.8847C67.683 57.2924 67.1232 58.6424 66.1268 59.6378C65.1304 60.6332 63.7791 61.1924 62.37 61.1924ZM14.63 40.9616H47.817V26.6539H60.445L72.688 40.5001L72.842 54.8847H69.146C68.9008 53.2647 68.0823 51.7863 66.8389 50.7179C65.5956 49.6495 64.01 49.062 62.37 49.062C60.73 49.062 59.1443 49.6495 57.901 50.7179C56.6577 51.7863 55.8391 53.2647 55.594 54.8847H31.108C30.8628 53.2647 30.0443 51.7863 28.8009 50.7179C27.5576 49.6495 25.972 49.062 24.332 49.062C22.692 49.062 21.1063 49.6495 19.863 50.7179C18.6197 51.7863 17.8011 53.2647 17.556 54.8847H14.63V40.9616ZM24.332 61.1924C23.2812 61.1924 22.2539 60.8811 21.3802 60.2979C20.5065 59.7147 19.8255 58.8857 19.4234 57.9159C19.0213 56.946 18.9161 55.8788 19.1211 54.8492C19.3261 53.8196 19.8321 52.8739 20.5751 52.1316C21.3181 51.3893 22.2648 50.8838 23.2955 50.679C24.3261 50.4742 25.3943 50.5793 26.3652 50.981C27.336 51.3828 28.1658 52.0631 28.7496 52.9359C29.3334 53.8088 29.645 54.8349 29.645 55.8847C29.645 57.2924 29.0852 58.6424 28.0888 59.6378C27.0924 60.6332 25.7411 61.1924 24.332 61.1924Z" fill="black"/>
  <path d="M58.058 31.1155H52.283V40.9616H66.451L58.058 31.1155ZM53.823 32.6539H57.365L63.14 39.4232H53.823V32.6539Z" fill="black"/>
  <path d="M18.095 15.1924V35.2693H41.349V15.1924H18.095ZM27.335 16.7308H32.109V20.6539H27.335V16.7308ZM39.809 33.7308H19.635V16.7308H25.795V22.1924H33.649V16.7308H39.809V33.7308Z" fill="black"/>
  <path d="M13.09 17.5H5.46698V19.0385H13.09V17.5Z" fill="black"/>
  <path d="M13.167 24.6538H2.61798V26.1923H13.167V24.6538Z" fill="black"/>
  <path d="M13.09 31.8848H5.46698V33.4232H13.09V31.8848Z" fill="black"/>
</svg>  
<h3>International Shipping</h3>
<p>Available to 10+ countries</p>
 </li>
           

        </ul>
        
        
    </Wrapper>
  )
}
const Wrapper = styled.section`
  ul {

  max-width:80%;
  margin:0px auto;
  display:grid;
  justify-content:space-between;
    padding:30px 0px;
    column-gap:30px;
  }
    ul li{
    display: flex;
padding: 20px 70px;
background:  #DFC0BE;
noun-return-2795742 1;
Icon · 54 x 67;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 5px;
flex: 1 0 0;
    }
h3{
  color: #000;
text-align: center;
font-family: "Playfair Display";
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
}
p{
color: #000;

text-align: center;
font-family: "Playfair Display";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
`;
export default ShippingPolicy