import React, {useState,useEffect } from 'react'
import { NavLink,useNavigate  } from "react-router-dom";
import InputField from '../common/inputField';
import { validateForm } from '../common/validation';
import axiosInstance from '../interceptors/axiosConfig';

function Login() {
  
  const navigate = useNavigate();
 
   // Redirect to home if token exists
   useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/');
    }
  }, [navigate]);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
 
    const [errors, setErrors] = useState({});
  
    const validationRules = {
     
      email: { required: true, type: 'email' },
      password: { required: true, type: 'password' },
  
    };
    

    

    const handleSubmit = async (e) => {
      e.preventDefault();

    const { isValid, validationErrors } = validateForm(formData, validationRules);
    if (isValid) {
      try {
        const res = await axiosInstance.post('/store/login',formData); // Example endpoint
        localStorage.setItem('authToken', res.data.token);
        navigate('/');
        setErrors({});
      } catch (err) {
        if (err.response && err.response.data.errors) {
          setErrors(err.response.data.errors);  // Show validation errors
        }
      }
      setFormData({
        
        email: '',
        password: '',
   

      });
      setErrors({});
    } else {
      alert("invalid form");
      setErrors(validationErrors);
    }
    };
  // Handle input change with real-time validation
const handleChange = (e) => {
  const { name, value } = e.target;

  // Update form data
  setFormData({
    ...formData,
    [name]: value,
  });

  // Validate the current field and update errors accordingly
  const { validationErrors } = validateForm(
    { ...formData, [name]: value },
    validationRules,
    name
  );

  setErrors({
    ...errors,
    ...validationErrors, // Update the current field's error
  });

  // Clear the error if the field is valid
  if (!validationErrors[name]) {
    const { [name]: removedError, ...restErrors } = errors;
    setErrors(restErrors);
  }
};
  return (
    <div className='container'> 
    <h2 className='text-center mb-4'>Your Account</h2>
    
    <form onSubmit={handleSubmit} className='formcontrols myform'>
        <div className='formrow'>
       
        <InputField
          label="Email"
          type="email"
          name="email"
          className='form-controls'
          placeholder='Email'
          value={formData.email}
          handleChange={handleChange}
          error={errors.email}
          
        />
         
         
        </div>
        <div className='formrow'>
           
        <InputField
          label="Password"
          type="password"
          name="password"
          className='form-controls'
          placeholder='Password'
          value={formData.password}
          handleChange={handleChange}
          error={errors.password}
          
        />
        </div>
        <div className='forgorpassword'> <NavLink to="/resetpassword" >  <u> Forgot your password?</u></NavLink></div>
        <div className='text-center'> 
        <button className='formbtn' type="submit">Login</button>
        <div className='mt-5'><NavLink to="/register" >  <u>  Create account</u></NavLink></div>
        </div>
      </form>
     
    </div>
  )
}

export default Login