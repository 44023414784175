import React from 'react';

// Reusable Input Component
const InputField = ({ label, type, name,className, placeholder,value, handleChange, error }) => {
  return (
    <div className="form-group mb-4">
      {/* <label className="block text-gray-700 font-semibold">{label}</label> */}
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        className={className}
        placeholder={placeholder}
      />
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </div>
  );
};

export default InputField;
