import React from 'react'
import { NavLink } from "react-router-dom"; 
import styled from "styled-components";

const products = [
  {
    id: 1,
    name: "Aiko",
    image: "../images/product1.jpg",
    price: 550.00,
    description: "High-quality wireless earbuds with noise cancellation and long battery life."
  },
  {
    id: 2,
    name: "Amelie",
    image: "../images/product2.jpg",
    price: 550.00,
    description: "Latest smartphone with a 6.5-inch display, quad-camera setup, and 5000mAh battery."
  },
  {
    id: 3,
    name: "Anne",
    image: "../images/product3.jpg",
    price: 550.00,
    description: "Powerful gaming laptop with a high-end graphics card and 16GB of RAM."
  },
  {
    id: 4,
    name: "Anouk",
    image: "../images/product4.jpg",
    price:  550.00  ,
    description: "Feature-packed smartwatch with heart rate monitoring, GPS, and 7-day battery life."
  },
  {
    id: 5,
    name: "Antoinette",
    image: "../images/product6.jpg",
    price: 550.00,
    description: "Portable Bluetooth speaker with deep bass and 10-hour playback time."
  },
  {
    id: 6,
    name: "Aurelia",
    image: "../images/product7.jpg",
    price: 550.00,
    description: "Portable Bluetooth speaker with deep bass and 10-hour playback time."
  },
  {
    id: 7,
    name: "Capucine",
    image: "../images/product4.jpg",
    price: 550.00,
    description: "Portable Bluetooth speaker with deep bass and 10-hour playback time."
  },
  {
    id: 8,
    name: "Catherine",
    image: "../images/product5.jpg",
    price: 550.00,
    description: "Portable Bluetooth speaker with deep bass and 10-hour playback time."
  }
];

function ProductList() {
  return (
    <Wrapper className="container grid grid-four-column gap-2">
  
        {products.map((curElem) => {
          const { id, name, image, price, description } = curElem;
          return (
            <NavLink to={`/singleproduct/${id}`} className="btn-main">
            <div key={id} className="card grid productlist ">
              <figure>
                <img src={image} alt={name} />
              </figure>

              <div className="card-data">
                <h2 className='text-center'>{name}</h2>
                <p className='text-center price font-bold'>
                $ {price}  USD
                </p>
                {/* <p>{description.slice(0, 90)}...</p> */}

               
                   
               
              </div>
            </div>
            </NavLink>
          );
          
        })}
      </Wrapper>
  )
}

const Wrapper = styled.div`
  

  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    height:372px;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.2);
    }
    img {
      
      transition: all 0.2s linear;
    }

     
  }

  .card {
    background-color: #fff;
    padding:5px;
   

    .card-data {
      padding: 0 10px;
    }

    .card-data-flex {
      
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h3 {
      color: ${({ theme }) => theme.colors.text};
      text-transform: capitalize;
    }

    .card-data--price {
      color: ${({ theme }) => theme.colors.helper};
    }

   
    }
  }
`;
export default ProductList