import React from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
 

const Footer = () => {
  const location = useLocation();
  return (
    <>
      <Wrapper >
        <div className="flex justify-center gap-5" style={{ padding: "50px 0px"}}> 
        <div  className="flex items-center">
          {
             location.pathname === "/"  ? 
             <img style={{alginSelf:"cemter"}} width="150" src="./images/footerlogo.png" alt="logo" /> :
             <img style={{alginSelf:"cemter"}} width="150" src="../images/footerlogo.png" alt="logo" /> 
          }
          
        </div>
        <div className="flex gap-10">
         
          <ul>          
          <li><h3>Customer Service</h3></li>
          <li><NavLink>Size Guide</NavLink></li>
          <li><NavLink>Returns and Refunds</NavLink></li>
          <li><NavLink>Turms & Conditions</NavLink></li>
          <li><NavLink>Privacy Policy</NavLink></li>
          <li><NavLink>Terms of Service</NavLink></li>
          </ul>
          <ul>          
          <li><h3>Faq</h3></li>
          <li><NavLink>Delivery and Returns</NavLink></li>
          <li><NavLink>Payment</NavLink></li>
          <li><NavLink>Contact Us</NavLink></li>
           
          </ul>
          <ul>          
          <li><h3>Contact Us</h3></li>
          <li><NavLink>on Whatsapp</NavLink></li>
          <li><NavLink>Send us a Note</NavLink></li>
         
          </ul> 
          </div>
          </div>
    <div className="copyright">Copyright 2022 Maison Ava™. All rights Reserved. Design and Developed by Sparsh. </div>
          
       
        
      </Wrapper>
    </>
  );
};

const Wrapper = styled.footer`
 li {
 margin-bottom:10px;
 color: #FFF;
font-family: "Playfair Display";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
 }
 li a:hover{
 text-decoration:underline;
 }
   h3{
   color: #FFF;
text-align: left;
font-family: "Stay Classy Duo Serif";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.1px;
text-transform:uppercase;
margin-bottom:20px;
   }

 .copyright{
 border: 1px solid rgba(170, 170, 170, 0.24);
 padding:30px 0px;
 text-align:center;
 font-size: 12px;
font-style: normal;
font-weight: 400;

 
 }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
     
  }
`;

export default Footer;
