 
import styled from "styled-components"; 
import MyImage from "./front-end/MyImage"; 
import FeatureProducts from "./front-end/FeatureProducts";
import OurJournals from "./front-end/OurJournals";
import ShippingPolicy from "./front-end/ShippingPolicy"; 
import  NewsLetterPage from "./front-end/NewsLetter";
import GloablStock from "./front-end/GloablStock";
 

const SingleProduct = () => {
 

 

  return (
    <Wrapper>
      {/* <PageNavigation title={name} /> */}
       
        <div className="grid ">
          {/* product Images  */}
          <div className="product_images">
            <MyImage   />
          </div>

          <div className="container">
            <h2 className="text-center" style={{margin:"90px 0px 30px"}}>Recommended Products </h2>
          <FeatureProducts /> 
          <ShippingPolicy /> 
      <OurJournals /> 
      </div>
      <NewsLetterPage />  
       
      <GloablStock></GloablStock> 

        </div>
      
    </Wrapper>
  );
};

const Wrapper = styled.section`
  
 
  .product_images {
    display: flex;
    align-items: center;
  }

  .product-data {
    display: flex;
    flex-direction: column;
   
     
    gap: 2rem;

    .product-data-warranty {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;

      .product-warranty-data {
        text-align: center;

        .warranty-icon {
          background-color: rgba(220, 220, 220, 0.5);
          border-radius: 50%;
          width: 4rem;
          height: 4rem;
          padding: 0.6rem;
        }
        p {
          font-size: 1.4rem;
          padding-top: 0.4rem;
        }
      }
    }

    .product-data-price {
      font-weight: bold;
    }
    .product-data-real-price {
      color: ${({ theme }) => theme.colors.btn};
    }
    .product-data-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.8rem;

      span {
        font-weight: bold;
      }
    }

    hr {
      max-width: 100%;
      width: 90%;
      /* height: 0.2rem; */
      border: 0.1rem solid #000;
      color: red;
    }
  }

  .product-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page_loading {
    font-size: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .grid-two-column {
    gap:1rem;}
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
   
  }
`;

export default SingleProduct;
