import styled from "styled-components";

export const Button = styled.button`
  text-decoration: none;
  max-width: auto;  
  color: #fff; 
  border: none;  
  text-align: center;
  cursor: pointer;

  &:hover,
  &:active {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
     
  }

  a {
    text-decoration: none;
    color: rgb(255 255 255);     
  }
`;
