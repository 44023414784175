// validation.js

export const validateForm = (formData, validationRules, fieldToValidate = null) => {
    let validationErrors = {};
    let isValid = true;
  
    const fields = fieldToValidate ? [fieldToValidate] : Object.keys(validationRules);
  
    // Loop through each field and validate based on rules
    fields.forEach((field) => {
      const value = formData[field];
      const rules = validationRules[field];
  
      // Required field validation
      if (rules.required && (!value || !value.trim())) {
        validationErrors[field] = `${capitalize(field)} is required`;
        isValid = false;
      }
  
      // Email validation
      if (rules.type === 'email' && value) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
          validationErrors[field] = 'Invalid email format';
          isValid = false;
        }
      }
  
      // Password validation (minimum 8 characters, at least 1 number)
      if (rules.type === 'password' && value) {
        // const passwordPattern = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
        // if (!passwordPattern.test(value)) {
        //   validationErrors[field] = 'Password must be at least 8 characters long and include a number';
        //   isValid = false;
        // }
      }
    
    });
  
    return { isValid, validationErrors };
  };
  
  // Helper function to capitalize field names
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  