import React, {useState} from 'react' 
import { useNavigate } from 'react-router-dom';
import InputField from '../common/inputField';
import { validateForm } from '../common/validation';
import axiosInstance from '../interceptors/axiosConfig';


function Register() {
   // State to store form data
   const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });

  // State to store errors
  const [errors, setErrors] = useState({});

  // Validation rules
  const validationRules = {
    first_name: { required: true },
    last_name: { required: true },
    email: { required: true, type: 'email' },
    password: { required: true, type: 'password' },

  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isValid, validationErrors } = validateForm(formData, validationRules);
    if (isValid) {
      console.log('Form data submitted:', formData);
      // Reset the form after successful submission
      try {
      
        
        const res = await axiosInstance.post('/store/sign_up',formData); // Example endpoint
        navigate('/');
        setErrors({});
      } catch (err) {
        if (err.response && err.response.data.errors) {
          setErrors(err.response.data.errors);  // Show validation errors
        }
      }
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        password: '',

      });
      setErrors({});
    } else {
      
      setErrors(validationErrors);
    }

  };
// Handle input change with real-time validation
const handleChange = (e) => {
  const { name, value } = e.target;

  // Update form data
  setFormData({
    ...formData,
    [name]: value,
  });

  // Validate the current field and update errors accordingly
  const { validationErrors } = validateForm(
    { ...formData, [name]: value },
    validationRules,
    name
  );

  setErrors({
    ...errors,
    ...validationErrors, // Update the current field's error
  });

  // Clear the error if the field is valid
  if (!validationErrors[name]) {
    const { [name]: removedError, ...restErrors } = errors;
    setErrors(restErrors);
  }
};

  return (
    <div>
        <h2 className='text-center mb-4'>Create account</h2>

        <form onSubmit={handleSubmit} className='formcontrols regform'>
        <div className='formrow'>
            {/* First Name */}
        <InputField
          label="First Name"
          type="text"
          name="first_name"
          className='form-controls'
          placeholder='First Name'
          value={formData.firstName}
          handleChange={handleChange}
          error={errors.firstName}
          
        />
         
        </div>
        <div className='formrow'>
        <InputField
          label="Last Name"
          type="text"
          name="last_name"
          className='form-controls'
          placeholder='Last Name'
          value={formData.lastName}
          handleChange={handleChange}
          error={errors.lastName}
          
        />
         
          
        </div>
        <div className='formrow'>
        <InputField
          label="Email"
          type="email"
          name="email"
          className='form-controls'
          placeholder='Email'
          value={formData.email}
          handleChange={handleChange}
          error={errors.email}
          
        />
       
        </div>
        <div className='formrow'>
        <InputField
          label="Password"
          type="password"
          name="password"
          className='form-controls'
          placeholder='Password'
          value={formData.password}
          handleChange={handleChange}
          error={errors.password}
          
        />
        
        </div>
        <div className='text-center'> 
        <button  className='formbtn' type="submit">Register</button>
        </div>
      </form>

    </div>
  )
}

export default Register