import React from 'react';
import styled from "styled-components";

function OurStore() {
  return (
    <Wrapper> 
      <VideoWrapper className="container">
      <video className="background-video" autoPlay loop muted>
        <source src="./images/store.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </VideoWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.section` 
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
 
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%; 
  margin-top:5px;
`;

export default OurStore;
