import React,{useState} from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import Nav from "./Nav";
import Headericons from "./Headericons";

const Header = () => {
  const [menuIcon, setMenuIcon] = useState();
  const location = useLocation();
  return (
    <MainHeader className={`${menuIcon ? "navbar activemenu" : "navbar"} ${
        location.pathname === "/" ? "homepage" : "otherpages"
      }` }>
      <div className="container"> 
        {
          location.pathname === "/"  &&  <div className="flex justify-end gap-4 items-center topbar"  >
          English (us) - USD ($) <img alt="flag" src="./images/usflag.png" />  
          </div>
        } 

      <div className="headerwrapper">  
      <div className="hamburger"  onClick={() => setMenuIcon(!menuIcon)} >
          <span></span>
          <span></span>
          <span></span>
      </div>  
      <NavLink to="/">     
 
      {location.pathname === "/" ? (
              <img  width="200px"  src="./images/maisonava.png" alt="maisonava_logo" />
            ) : (
              <img  width="200px" src="../images/logo1.png" alt="maisonava_logo" />
            )}
      </NavLink>
      <Nav menuIcon={setMenuIcon} />
      <Headericons  />  
      </div>
      </div>
    </MainHeader>
  );
};

const MainHeader = styled.header`
  padding: 10px 0px;
  position:absolute;
  width:100%;
  z-index:9;
  
.topbar{  
font-family: "Playfair Display";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
padding-bottom:10px;
border-bottom:1px solid rgba(255, 255, 255, 0.30);
}

 
.headerwrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:20px 0px;
  position:relative;
  }
  .logo {
    height: 5rem;
  }
    .mobile-navbar-btn {
      display: none;
      background-color: transparent;
      cursor: pointer;
      border: none;
    }

   .mobile-nav-icon[name="close-outline"] {
      display: none;
    }
  
    @media (max-width: ${({ theme }) => theme.media.lgwindow}) {
     .mobile-navbar-btn {
        display: inline-block;
        z-index: 9999;
        border: ${({ theme }) => theme.colors.white};

        .mobile-nav-icon {         
          color: ${({ theme }) => theme.colors.white};
        }
      }

      .active .mobile-nav-icon {
        display: none;      
       
        color: ${({ theme }) => theme.colors.white};
        z-index: 9999;
      }

      .active .close-outline {
        display: inline-block;
      }

}
       .close-outline {
      display: none;
    }

   
`;
export default Header;
