 
import FeatureProducts from "./front-end/FeatureProducts";
 
import HeroSection from "./front-end/HeroSection";
import HomeHeroBanner from "./front-end/HomeHeroBanner";
import OurJournals from "./front-end/OurJournals";
import OurStore from "./front-end/OurStore";
 
import ShippingPolicy from "./front-end/ShippingPolicy";
 
import  NewsLetterPage from "./front-end/NewsLetter";
import GloablStock from "./front-end/GloablStock";
const Home = () => {
  const data = {
    name: "Exquite Couture, <br/> for Boys and Girls",
  };

  return (
    <>
    
      <HeroSection myData={data} />
      <HomeHeroBanner />  
      <FeatureProducts />  
      <OurStore /> 
      <OurJournals />  
     
      {/* <FeatureProduct /> */}
      <ShippingPolicy />  
      <NewsLetterPage />  
      <GloablStock></GloablStock>
      {/* <Services /> */}
      {/* <Trusted /> */}
    </>
  );
};

export default Home;
