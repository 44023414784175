import React from 'react'
import styled from "styled-components";
function FeatureProducts() {
  return (
    <Wrapper className="container">
        <ul className='grid grid-three-column'>
            <li><img src="../images/categoriesimg1.jpg" alt="prodctimg" /> 
             {/* <button> Girls</button> */}
              </li>
            <li><img src="../images/categoriesimg2.jpg" alt="prodctimg" /> 
            {/* <button>Baby Girls</button> */}
              </li>
            <li><img src="../images/categoriesimg3.jpg" alt="prodctimg" />
            {/* <button> boys</button> */}
               </li>
        </ul>
        
    </Wrapper>
  )
}
const Wrapper = styled.section`
ul{
margin-top:10px;
 display:grid;
 column-gap:5px;
}
 li{
 position:relative;
 text-align:center;
 }
 button{
 
 font-size:12px;
 padding:0.5rem 1.5rem;
 background:#fff;
 color:#000;
text-align: center; 
font-family: "Playfair Display";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 30px; 
letter-spacing: 1.6px;
text-transform: uppercase;
 }
`;
export default FeatureProducts