import React, { useState, useEffect,useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'; // Import react-zoom-pan-pinch
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faChevronLeft, faChevronRight  } from '@fortawesome/free-solid-svg-icons';


import 'react-medium-image-zoom/dist/styles.css';

export default function MyImage() {
  const [slidesToShow, setSlidesToShow] = useState(6);
  const [zoomedImage, setZoomedImage] = useState(null); // State for zoomed image
 
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1); 
  const [images, setImages] = useState([]);
  const { id } = useParams();
  
  useEffect(() => {
    // Set images based on id
    if (id == 11) {
      setImages([
        { small: '../images/01.webp', large: '../images/01.webp' },
        { small: '../images/02.webp', large: '../images/02.webp' },
        { small: '../images/03.webp', large: '../images/03.webp' },
        { small: '../images/04.webp', large: '../images/04.webp' },
        { small: '../images/05.webp', large: '../images/05.webp' },
        { small: '../images/06.webp', large: '../images/06.webp' },
      ]);
    } else if (id == 22) {
      setImages([
        { small: '../images/001.webp', large: '../images/001.webp' },
        { small: '../images/002.webp', large: '../images/002.webp' },
        { small: '../images/003.webp', large: '../images/003.webp' },
        { small: '../images/004.webp', large: '../images/004.webp' },
        { small: '../images/005.webp', large: '../images/005.webp' },
        { small: '../images/001.webp', large: '../images/001.webp' },
      ]);
    } else {
      setImages([
        { small: '../images/0001.webp', large: '../images/0001.webp' },
        { small: '../images/0002.webp', large: '../images/0002.webp' },
        { small: '../images/0003.webp', large: '../images/0003.webp' },
        { small: '../images/0004.webp', large: '../images/0004.webp' },
        { small: '../images/0005.webp', large: '../images/0005.webp' },
        { small: '../images/0001.webp', large: '../images/0001.webp' },
      ]);
    }
  }, [id]);
 
 

  // Update slidesToShow based on window width
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setSlidesToShow(width); // For desktop
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen for window resize
    return () => window.removeEventListener('resize', handleResize); // Cleanup event listener on unmount
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    swipeToSlide: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleImageClick = (image) => {
    setZoomedImage(image.large); // Set clicked image as the zoomed one
    setZoomLevel(1); // Reset zoom level to 1
  };

  const handleCloseZoom = () => {
    setZoomedImage(null); // Close the zoomed image
  };
 

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? images.length - 1 : prevIndex - 1;
      setZoomedImage(images[newIndex].large); // Update zoomedImage
      return newIndex;
    });
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === images.length - 1 ? 0 : prevIndex + 1;
      setZoomedImage(images[newIndex].large); // Update zoomedImage
      return newIndex;
    });
  };
    
  return (
    <Wrapper>
             <div className="sliderimages" style={{ maxWidth: slidesToShow }}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} style={{ padding: '0 10px' }}>
              <img
                src={image.small}
                alt={`slide-${index}`}
                style={{
                  width: '100%',
                  height: 'auto',
                  cursor:'pointer'
                }}
                onClick={() => handleImageClick(image)} // Click to zoom
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* Render zoomed image with zoom and pan functionality */}
      {zoomedImage && (
       <ZoomedImageWrapper>
      <TransformWrapper
        doubleClick={{ disabled: true }}
        defaultScale={1}
        maxScale={2}
        defaultPositionX={100}
        defaultPositionY={100}
        maxPositionY= {10}
        
      >
        {({ zoomIn, zoomOut, resetTransform, scale, positionX, positionY, ...rest }) => (
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute",width:"100%", padding:" 0 5rem", justifyContent:"space-between", fontSize:"25px", bottom:"20px", zIndex: "9999", display: "flex", columnGap: "20px" }}>
            
            <button className='circle' onClick={handlePrev} > 
              <FontAwesomeIcon icon={faChevronLeft } />
              </button>

              <button className='circle' onClick={resetTransform}> 
              <FontAwesomeIcon icon={faUndo } />
              </button>
              <button className='circle' onClick={handleNext}> 
              <FontAwesomeIcon icon={faChevronRight } />
              </button>
            </div>
            <TransformComponent>
              {zoomLevel ? (
                <div onClick={zoomIn} className='resetimage'>
                  <img
                    src={zoomedImage}
                    alt="zoomed"
                    style={{
                      width: '100%',
                      height: 'auto',
                      cursor: 'zoom-in',
                      maxHeight: 'calc(100vh - 20px)',
                    }}
                  />
                </div>
              ) : (
                <div className='zoomimage' onClick={resetTransform}>
                  <img
                    src={zoomedImage}
                    alt="zoomed"
                    style={{
                      width: '100%',
                      height: 'auto',
                      cursor: 'zoom-out',
                      maxHeight: 'calc(100vh - 220px)',
                    }}
                  />
                </div>
              )}
            </TransformComponent>
          </div>
        )}
      </TransformWrapper>
      <CloseButton onClick={handleCloseZoom}>X</CloseButton>
    </ZoomedImageWrapper>
      )}


<div className='container'>  

<div className='grid grid-two-column'>
  <div>  

      <div className='breadcrumbs'>  Home {'>'}Product  </div>
      <h1 className='titletext'>
      Alba dress white the smallest floral liberty print
      </h1>

          <div>
            <ul className='sizeslist'>
              <li>1Y</li>
              <li>2Y</li>
              <li>4Y</li>
              <li>6Y</li>
              <li>8Y</li>
              <li>10Y</li>
              <li>12Y</li>
              <li>14Y</li>
            </ul>
          </div>


          <div className='sizechart'>
          Size Chart   <svg xmlns="http://www.w3.org/2000/svg" width="37" height="19" viewBox="0 0 37 19" fill="none">
                  <path d="M33.226 2.92308H3.77404C2.62704 2.92308 1.66504 4.18975 1.66504 5.7V15.2487C1.66504 16.759 2.62704 18.0256 3.77404 18.0256H33.226C34.373 18.0256 35.335 16.759 35.335 15.2487V5.7C35.335 4.18975 34.373 2.92308 33.226 2.92308ZM33.67 15.2487C33.67 15.5897 33.485 15.8333 33.226 15.8333H3.77404C3.51504 15.8333 3.33004 15.541 3.33004 15.2487V5.7C3.33004 5.35898 3.51504 5.11539 3.77404 5.11539H6.29004V8.57436H7.95504V5.11539H10.878V8.57436H12.543V5.11539H15.466V8.57436H17.131V5.11539H20.054V8.57436H21.719V5.11539H24.642V8.57436H26.27V5.11539H29.193V8.57436H30.858V5.11539H33.263C33.522 5.11539 33.707 5.35898 33.707 5.7V15.2487H33.67Z" fill="#414042"/>
                  </svg>

          </div>
  </div>

  <div> 
        <div>
        <span className='price'> $190.50 USD</span> <span className='salefprice'>$290.50</span>        
        </div>
        <div className='infodata'>
          <p>Tax included. Shipping calculated at checkout</p>
        </div>
        <div className='flex shopping-btnlist'>
          <button className='btnaddtobag'>Add to Bag <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
<path d="M14.3498 5.43664C14.4552 5.43664 14.5402 5.52464 14.5402 5.63376V15.9192C14.5402 16.0283 14.4552 16.1163 14.3498 16.1163H3.65004C3.54464 16.1163 3.45965 16.0283 3.45965 15.9192V5.63552C3.45965 5.5264 3.54464 5.4384 3.65004 5.4384H14.3498V5.43664ZM15.6877 5.63552C15.6877 4.86992 15.0893 4.2504 14.3498 4.2504H3.65004C2.91054 4.2504 2.31215 4.86992 2.31215 5.63552V15.921C2.31215 16.6866 2.91054 17.3061 3.65004 17.3061H14.3498C15.0893 17.3061 15.6877 16.6866 15.6877 15.921V5.63552ZM8.99994 1.48192C10.161 1.48192 11.1011 2.45696 11.1011 3.65728V6.42576C11.1011 6.75312 11.3578 7.01888 11.674 7.01888C11.9902 7.01888 12.2469 6.75312 12.2469 6.42576V3.65728C12.2469 1.80048 10.7934 0.293919 8.99824 0.293919C7.20304 0.293919 5.74954 1.79872 5.74954 3.65728V6.42576C5.74954 6.75312 6.00624 7.01888 6.32244 7.01888C6.63864 7.01888 6.89534 6.75312 6.89534 6.42576V3.65728C6.89874 2.4552 7.83884 1.48192 8.99994 1.48192Z" fill="#414042"/>
</svg></button>  <button className='btnbuynow'>Buy now</button>
        </div>

        <div className='infodata2'>
          <p>Don't see your size? Contact us via Ava Privé</p>
        </div>
  </div>

</div>
</div>

    </Wrapper>
  );
 
}
 
 const Wrapper = styled.div`
 
 .sliderimages{
 margin-bottom:45px;
 }
 .slick-slide{
 padding:0px 5px;
 }
 .circle{
 width:50px;
 height:50px;
 border:1px solid #aeaeae;
 background:#fff !important;
 border-radius:50%;
 
 } 
.resetimage {
 cursor:zoom-in;
}
 /* Styles for the popover */
  .popover {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 999;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .popover img {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 5px;
  }

  .popover.active {
    transform: translate(-50%, -50%) scale(1);
  }

 
.react-transform-component{
 overflow:visible;
}

.breadcrumbs{ 
font-family: "Playfair Display";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
 }
.titletext{
text-align:left;  
font-family: "Stay Classy Duo Serif";
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 35px; /* 109.375% */
text-transform: uppercase;
margin:25px 0px;
}
ul.sizeslist { display:flex; column-gap:10px; margin-bottom:25px;}
ul.sizeslist li {
display: flex;
width: 40px;
height: 40px;
justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid   #414042;
transition: transform 0.3s ease; 
cursor:pointer;
}
ul.sizeslist li:hover{
transform: scale(0.9);
}
.sizechart {
display:flex;
column-gap:10px; 
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}
.price{ 
font-family: "Stay Classy Duo Serif";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}
.salefprice{ 
font-family: "Stay Classy Duo Serif";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
text-decoration: line-through;
}
.infodata{
 margin:15px 0px;
font-family: "Playfair Display";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.infodata2{
font-family: "Playfair Display";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
margin-top:20px;
}
.shopping-btnlist{
 column-gap:20px; 
 margin:15px 0px; 
}
.btnaddtobag{
display: flex;
height: 51px;
max-width: 200px;
padding: 20px 30px;
justify-content: center;
align-items: center;
gap: 10px;
flex: 1 0 0;
border: 1px solid   #DFC0BE;
background:   #DFC0BE;
leading-trim: both; 
font-family: "Playfair Display";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
transition: transform 0.3s ease;
}
.btnbuynow {
background: #FFF;
display: flex;
height: 51px;
max-width: 200px;
padding: 20px 30px;
justify-content: center;
align-items: center;
gap: 10px;
flex: 1 0 0;
color: #000; 
font-family: "Playfair Display";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
transition: transform 0.3s ease; 
}
.btnaddtobag:hover,.btnbuynow:hover {
  transform: scale(0.9);
}
  .react-transform-wrapper {
   cursor:zoom-in;
  }
 
@media only screen and (max-width: 1200px) {
.shopping-btnlist,.infodata2{ {
justify-content: center;
text-align:center;
}
}
 `
 const ZoomedImageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ZoomedImageContainer = styled.div`
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #000;
  border: 1px solid #000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 22px;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;
const ZoomedImageWrapper = styled.div`
  position: fixed;
    
  top: 0; 
  left:0px;
  width:100%;
  min-height:100vh;
  z-index: 10;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  display:flex;
  justify-content:center;
  z-index: 999;
  animation: zoomIn 0.4s ease-out forwards;

  /* Define zoomIn keyframes */
  @keyframes zoomIn {
    from {
      transform: scale(0.3); /* Start small */
      opacity: 0; /* Start transparent */
    }
    to {
      transform: scale(1); /* End at full size */
      opacity: 1; /* Fully visible */
    }
  }
`;