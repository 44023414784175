 
import styled from "styled-components";
import Product from "./Product";

const OurJournals = () => {
  
  const featureProductsnew =
     [{"id":"11","name":"Louise Bourgeois, Material Girl", "price":500,"colors":["#ff0000","#000000","#CDD0D0"],"image":"../images/01.webp","description":"      means you can watch 4K content on it.","category":"kids","featured":true},{"id":"22","name":"The Amelie co-ord set showcases ","company":"samsung","price":5000000,"colors":["#000","#22D3EF"],"image":"../images/001.webp","description":"       you can watch 4K content on it.","category":"girls","shipping":true},{"id":"33","name":"The Amelie co-ord set showcases ","company":"dell","price":600000,"colors":["#22D3EF","#CDD0D0"],"image":"../images/0001.webp","description":"       means you can watch 4K content on it.","category":"girls"},{"id":"maisonava","name":"The Amelie co-ord set showcases ","company":"nokia","price":12599,"colors":["#000","#000000","#CDD0D0"],"image":"../images/product3.jpg","description":"    It also support HDR content means you can watch 4K content on it.","category":"girl","shipping":true},{"id":"maisonava","name":"The Amelie co-ord set showcases ","company":"apple","price":4000099,"colors":["#000","#CDD0D0"],"image":"../images/product4.jpg","description":"means you can watch 4K content on it.","category":"computer","shipping":true},{"id":"maisonava","name":"The Amelie co-ord set showcases ","company":"apple","price":42999,"colors":["#000000","#CDD0D0"],"image":"../images/0001.webp","description":" HDR content means you can watch 4K content on it.","category":"girl","shipping":true}]
  
  return (
    <Wrapper className="section">
      <div className="container">
         <h2 className="text-center">Ava Diaries</h2>
        <div className="grid grid-three-column">
          {featureProductsnew.map((curElem) => {
            return <Product hideprice={true} key={curElem.id} {...curElem} />;
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section` 
.grid-three-column{
column-gap: 5px;
}
.grid-template-columns a{
flex:1}
h2{
margin: 60px 0px 30px;

}
  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    height:500px;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.2);
    }
    img {
      
      transition: all 0.2s linear;
    }
 
  }

  .card {   
    border-radius: 1rem;
    .card-data {
      
    }

    .card-data-flex {    
      display: flex;
      justify-content:  center;
      align-items: center;
      margin-bottom:0.5rem;
    }

    h3 {
      color: ${({ theme }) => theme.colors.black};
      text-transform: capitalize;
    }

    .card-data--price {
      color: ${({ theme }) => theme.colors.black};
    }

 
  }
`;

export default OurJournals;

 

 