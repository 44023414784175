import { createGlobalStyle } from "styled-components";
 
export const GlobalStyle = createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 font-family: "Playfair Display";
}


html { 
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
   scrollbar-color: rgb(98 84 243);
    scrollbar-width: thin;
      background:   ${({ theme }) => theme.colors.bg};
  color: ${({ theme }) => theme.colors.black};
}

body::-webkit-scrollbar {
  width: 1.5rem;
}

body::-webkit-scrollbar-track {
   background-color: rgb(24 24 29);
}
 
a {
  text-decoration: none;
}

li {
  list-style: none;
}


${"" /* resuable code section  */}

 

.grid {
  display: grid;  
}

.grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}

.grid-three-column {
  grid-template-columns: repeat(3, 1fr);
}

.grid-four-column{
   grid-template-columns: repeat(4, 1fr) ;
}

.grid-five-column{
  grid-template-columns: repeat(5, 1fr);
}

  .common-heading {
      font-size: 3.8rem;
      font-weight: 600;
      margin-bottom: 6rem;
      text-transform: capitalize;
    }

     .intro-data {
      margin-bottom: 0;
      text-transform: uppercase;
      color: #5138ee;
    }

   .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.colors.bg};
      color: ${({ theme }) => theme.colors.helper};
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
    }

input, textarea{
    max-width: 50rem;
    color: ${({ theme }) => theme.colors.black};
   
    border: 1px solid ${({ theme }) => theme.colors.border};
   
   box-shadow: ${({ theme }) => theme.colors.shadowSupport};
}
    input[type="submit"]{
    max-width: 16rem;
    margin-top: 2rem;
    background-color: ${({ theme }) => theme.colors.btn};
    color: ${({ theme }) => theme.colors.white};
    padding: 1.4rem 2.2rem;
    border-style: solid;
    border-width: .1rem;
    text-transform: uppercase;
    font-size: 1.8rem;
    cursor: pointer;
    }

@media (max-width: ${({ theme }) => theme.media.tab}) {
      .herosection {
      height:600px !important;
      }
    .grid-four-column{
          grid-template-columns: 1fr 1fr;
          row-gap: 15px;
     }
      .grid-two-column{
            grid-template-columns: 1fr ;
            row-gap: 15px;
      }               
  }

   @media (max-width: ${({ theme }) => theme.media.mobile}) {
 
   
      .grid-two-column , .grid-three-column, .grid-four-column{
          grid-template-columns: 1fr;
                  row-gap: 15px;
        }
    }

`;
