import React from 'react'
import styled from "styled-components";
function HomeHeroBanner() {
  return (
    <Wrapper className="secbg">
       <div className='mb-3'> <img src="./images/logo1.png" width="200px" />   </div>
        
        <p>
        Youthful yet steeped in luxury, Maison ava's unique garments make the <br/>
 most of the highest quality fabrics and adornments to dress truly unique <br />
 boys and girls
            </p>
    </Wrapper>
  )
}
const Wrapper = styled.section`
padding:2rem 0;
text-align:center;
img{
display:inline-block;
}
p{
color: #000;

text-align: center;
font-family: "Stay Classy Duo Serif";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 30px;}
`;
export default HomeHeroBanner