import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
  
    const validate = () => {
      const errors = {};
      if (!email) errors.email = 'Email is required';
      else if (!/\S+@\S+\.\S+/.test(email)) errors.email = 'Email address is invalid';
  
      setErrors(errors);
      return Object.keys(errors).length === 0;
    };
  
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
      if (e.target.value) {
        setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (validate()) {
        // Handle forgot password logic
        console.log('Password reset email sent to', email);
      }
    };
  return (
    <div>
        <h2 className='text-center mb-4'>Forgot Password</h2>
      <form onSubmit={handleSubmit}  className='formcontrols myform'>
        <div className='formrow'>
          {/* <label htmlFor="email">Email:</label> */}
          <input
          placeholder='Email'
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            className='form-controls'
          />
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
        </div>
        <div className='text-center'> 
        <button  className='formbtn mb-5'  type="submit">Submit</button> <br/>  
        <NavLink to="/login" ><u>Cancel</u></NavLink>
        </div>
      </form>
        

    </div>
  )
}

export default ResetPassword